<template>
  <v-form>
    <!-- Check In Comment -->
    <v-row dense>
      <v-col>
        <v-textarea
          :label="labelComment"
          v-model="checkInComment"
          outlined
        ></v-textarea>
      </v-col>
    </v-row>

    <!-- OCR option -->
    <v-row dense>
      <v-col>
        <v-checkbox
          v-show="false"
          dense
          v-model="isCheckInOCR"
          :label="labelOCR"
        ></v-checkbox>
      </v-col>
    </v-row>

    <!-- Draft option -->
    <v-row dense>
      <v-col>
        <v-checkbox
          dense
          v-model="isCheckInDraft"
          :label="labelDraft"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  checkInOptionName,
  createCheckInOption,
  findCheckInOption
} from "@/model/record/checkInModel";

export default {
  name: "CheckInOption",
  props: {
    checkInOption: {
      type: Object,
      required: true,
      // Specify default as expected Object
      default: () => createCheckInOption()
    }
  },
  computed: {
    /**
     * compute label Comment
     * @return {string} label Comment
     */
    labelComment() {
      return findCheckInOption(checkInOptionName.comment)?.label;
    },

    /**
     * compute label OCR option
     * @return {string} label OCR option
     */
    labelOCR() {
      return findCheckInOption(checkInOptionName.ocr)?.label;
    },

    /**
     * compute OCR Tooltip (display full business logic description)
     * @return {string}
     */
    tooltipOcr() {
      return findCheckInOption(checkInOptionName.ocr)?.description;
    },

    /**
     * compute label Draft option
     * @return {string} label Draft option
     */
    labelDraft() {
      return findCheckInOption(checkInOptionName.draft)?.label;
    },

    /**
     * compute Draft Tooltip (display full business logic description)
     * @return {string}
     */
    tooltipDraft() {
      return findCheckInOption(checkInOptionName.draft)?.description;
    },

    /**
     * Get/set checkInComment
     */
    checkInComment: {
      get() {
        return this.checkInOption.checkInComment;
      },
      set(value) {
        this.checkInOption.checkInComment = value;
      }
    },

    /**
     * Get/set isCheckInDraft
     */
    isCheckInDraft: {
      get() {
        return this.checkInOption.isCheckInDraft;
      },
      set(value) {
        this.checkInOption.isCheckInDraft = value;
      }
    },

    /**
     * Get/set isCheckInOCR
     */
    isCheckInOCR: {
      get() {
        return this.checkInOption.isCheckInOCR;
      },
      set(value) {
        this.checkInOption.isCheckInOCR = value;
      }
    }
  }
};
</script>

<style scoped></style>
